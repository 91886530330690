
import React, {useEffect, useState, forwardRef, useRef, useImperativeHandle} from 'react';
import { BrowserRouter as Router, useLocation, Link } from 'react-router-dom';
import $ from "jquery";

import DelayLink from "../../components/DelayLink";

require('jquery.easing');

const Contents = forwardRef((props, ref) => {


	let [menuOpen, setMenuOpen] = useState(false);

	useImperativeHandle(ref, () => ({

		setMenuOpen
	}))

	const onMenuClick = (e) => {

		setMenuOpen(!menuOpen)

	}

	useEffect(()=>{
		
		if(menuOpen) {

			$(".gnb-chk").addClass("color-white");
			
			$("#gnb").stop().slideDown(1000, "easeInOutExpo").css('opacity', 0).animate({opacity: 1}, {queue: false, duration: 1000});
			$(".gnb-chk").removeClass("on");
			// $(".works-header").fadeOut();
			if($(".gnb-chk").hasClass("policy")){
				$(".gnb-chk").removeClass("policy");
				$(".gnb-chk").addClass("policy_hide");
			}

			
		} else {

			
			setTimeout(function(){$("#gnb").stop().slideUp(1000, "easeInOutExpo")},100);
			setTimeout(function(){
				$(".gnb-chk").removeClass("color-white");
				// $(".works-header").fadeIn()
				if($(".gnb-chk").hasClass("policy_hide")){
					$(".gnb-chk").removeClass("policy_hide");
					$(".gnb-chk").addClass("policy");
				}
			},800);

			if($(window).scrollTop() > 1){
				setTimeout(function(){$(".gnb-chk").addClass("on");},800);
			}
			if($(this).hasClass("policy")){
				$("#gnb").removeClass("policy");
				$("#gnb").addClass("policy_hide");
			}


		}

	

		//componentWillUnmount
		return ;

	}, [menuOpen])

	return (
		<>
			<div className={'gnb-chk' + (props.isMain ? " main" : "") + (props.showMenu ? "" : " work-detail")}>
				<h1 className="top-logo">
					<DelayLink to={"/"}

						delay={500}

						whenDelayStart={ ()=> {
							
							$('body').addClass("blur");
							$("body").stop().animate({transform:'scale(1.3)', opacity:0}, 800);

						} }
						whenDelayEnd={ ()=> {
							
							
							$("body").stop().animate({transform:'scale(1)', opacity:1}, 800);
							$('body').removeClass("blur");

						} }
						>
					<span className="blind">Aviv play</span></DelayLink></h1>
				<header>
				  <button type="button" className={'btn-menu' + (menuOpen ? " open" : "")} onClick={onMenuClick}>
					<span></span>
					<span></span>
					<span></span>
				  </button>
				</header>
			  </div>



			  <nav id="gnb" className={(menuOpen ? "show" : "hide")}>
				<div className="menu-con">
				  <ul>
					<li><DelayLink to={"/About"}

delay={500}

whenDelayStart={ ()=> {
	
	$('body').addClass("blur");
	$("body").stop().animate({transform:'scale(1.3)', opacity:0}, 800);

} }
whenDelayEnd={ ()=> {
	
	
	$("body").stop().animate({transform:'scale(1)', opacity:1}, 800);
	$('body').removeClass("blur");

} }
>about</DelayLink></li>
					<li><DelayLink to={"/Studio"}

delay={500}

whenDelayStart={ ()=> {
	
	$('body').addClass("blur");
	$("body").stop().animate({transform:'scale(1.3)', opacity:0}, 800);

} }
whenDelayEnd={ ()=> {
	
	
	$("body").stop().animate({transform:'scale(1)', opacity:1}, 800);
	$('body').removeClass("blur");

} }
>studio</DelayLink></li>
					<li><DelayLink to={"/Works"}

delay={500}

whenDelayStart={ ()=> {
	
	$('body').addClass("blur");
	$("body").stop().animate({transform:'scale(1.3)', opacity:0}, 800);

} }
whenDelayEnd={ ()=> {
	
	
	$("body").stop().animate({transform:'scale(1)', opacity:1}, 800);
	$('body').removeClass("blur");

} }
>works</DelayLink></li>
					<li><DelayLink to={"/Contact"}

delay={500}

whenDelayStart={ ()=> {
	
	$('body').addClass("blur");
	$("body").stop().animate({transform:'scale(1.3)', opacity:0}, 800);

} }
whenDelayEnd={ ()=> {
	
	
	$("body").stop().animate({transform:'scale(1)', opacity:1}, 800);
	$('body').removeClass("blur");

} }
>contact us</DelayLink>

</li>
				  </ul>
				  <div className="contact-con">
					<div className="contact-group">
					  <div className="con-itemWrap">
						<i className="ico-contact tel"></i>
						<div className="con-item">
						  <span>Tel</span>
						  <span>02-529-2647</span>
						</div>
					  </div>
					  <div className="con-itemWrap">
						 <i className="ico-contact mail"></i>
						 <div className="con-item">
						   <span>Mail</span>
						   <span>contact@avivland.com</span>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </nav>
		</>
	);
})



const Header = forwardRef((props, ref) =>{

	const contentRef = useRef(null);

	const location = useLocation();
	let headerClassName = "narrow";
	//let [showMenu, setShowMenu] = useState(true);

	let [showMenu, setShowMenu] = useState(true);

	if(location.pathname.toLowerCase() == '/works')
	{
		headerClassName = "";
		//setShowMenu(false);
	}
	else
	{

	}


	useImperativeHandle(ref, () => ({

		setMenuOpen
	}))

	function setMenuOpen(menuOpen) {
		contentRef.current.setMenuOpen(menuOpen)
	}


	useEffect(() => {

		console.log("location.pathname", location.pathname);

		if(location.pathname == '' || location.pathname == '/')
		{
			$("#wrapper").removeClass("sub");
		}
		else
		{

			$("#wrapper").addClass("sub");
		}

		if(location.pathname.toLowerCase().match(/works\/[0-9]+/))
		{
			console.log("location.pathname.toLowerCase():" + location.pathname.toLowerCase());
			//setShowMenu(false);
			setShowMenu(false);
		}
		else
		{
			setShowMenu(true);
		}

		// $(".pointer").removeClass("hover");

	}, [location.pathname]);


	useEffect(() => {
		

	}, [showMenu])

	if(location.pathname == '' || location.pathname == '/')
	{
		return (
			<>
				<Contents ref={contentRef} showMenu={showMenu} isMain={true}/>
			</>
		  );
	}
	else
	{
		return (
			<>
				<Contents ref={contentRef} showMenu={showMenu} isMain={false}/>
			</>
		  );
	}

  })



export default Header;
