
import React from 'react';
import { useLocation } from 'react-router-dom';

function Footer(props) {

	const location = useLocation();

  if(!location.pathname.toLowerCase().match(/works\/[0-9]+/) && (location.pathname.toLowerCase() != '/works'))
  {
    return (
  	<footer id="footer" className={props.isSection ? "section sec5 fp-auto-height" : ""}>
		<div className="footer-inner">
			<div className="fR">
				<ul className="company-info">
					<li>Tel.  02-529-2647</li>
					<li>Project request.  contact@avivland.com</li>
				</ul>
				<a href="https://avivland.com/Policy" className="txt-link" target="_blank">개인정보처리방침</a>
			</div>
			<div className="fL">
				<p>아비브 본사 : 경기도 성남시 분당구 판교로 323, 벤처포럼 벤처타워 1F 아비브</p>
				<p>아비브 스튜디오 : 서울특별시 서초구 마방로6길 7-40 지하 1층</p>
				<ul className="company-info">
					<li>Tel.  02-529-2647</li>
					<li>Project request.  contact@avivland.com</li>
				</ul>
				<p>ⓒCopyright all right reserved 2023</p>
			</div>
		</div>
	</footer>
    );
  } else {
    return null;
  }
}

export default Footer;
