
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


import Footer from "../includes/Footer"


import { Navigation, Pagination, Scrollbar, A11y, Mousewheel, EffectFade, Grid, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function Studio(props){

    let settings = {

        spaceBetween: 3,   
        slidesPerView : 4.2, 
        centeredSlides: true, 
        speed : 1000,
        autoplay: {
            delay: 1000, 
            disableOnInteraction: false,
        },
        loop : true, 
        breakpoints: {
            1080: {
                spaceBetween: 3,   
                slidesPerView : 4.2, 
            },
            768: {
                spaceBetween: 2,   
                slidesPerView: 3.5,
            },
            480: {
                spaceBetween: 2,   
                slidesPerView: 2.5,
            },
            200: {
                spaceBetween: 1,   
                slidesPerView: 1,
            },
        }
    }

	useEffect(()=>{

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
		
		if(typeof props.onBind == 'function')
        {
          props.onBind();
        }

	}, []);


    useEffect(() => {
        AOS.init();
    })

    return (
        <>
            <div className="sub-container">

            <div className="sub-top studio">
                <div className="sub-top-inner">
                    <h2 data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="500">STUDIO</h2>
                </div>
            </div>

            <div className="sub-con">
                <div className="studio-con">
                    <h3 data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">AVIV STUDIO</h3>
                    <p className="sub-txt" data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-out-cubic" data-aos-duration="1000">크로마키 호리존과 프리뷰/모니터링 시스템을 갖춘 스튜디오이며, <br/>촬영과 3D 영상제작, 라이브 스트리밍 등 콘텐츠 제작에 최적화 된 공간입니다.</p>
                    <Swiper className="studio-slide swiper" modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel, Grid, Autoplay]} {...settings} data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                        <ul className="swiper-wrapper">
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide1.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide2.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide3.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide4.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide5.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide6.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide7.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide8.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide9.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide10.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide11.png")} alt=""/></div></SwiperSlide>
                            <SwiperSlide className="swiper-slide"><div className="round-box"><img src={require("../images/photo/studio-slide12.png")} alt=""/></div></SwiperSlide>
                        </ul>
                    </Swiper>
                </div>

                <div className="studio-con">
                    <div className="con-width">
                        <h3 data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000">호리존 정보</h3>
                        <p className="sub-txt" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">촬영을 위한 차음/방음, 룸어쿠스틱 시공 되었으며<br/>고정형 바텐 설치로 조명 설치에 용이합니다.</p>
                        <div className="studio-img-area" data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                            <div className="hover-evt round-box wide">
                                <div className="hover-info"><p>마우스를<br/>올려주세요.</p></div>
                                <div className="img-wrap hidden"><img src={require("../images/photo/studio1-hover.png")} alt=""/></div>
                                <div className="img-wrap "><img src={require("../images/photo/studio1.png")} alt=""/></div>
                            </div>
                                <ul className="img-list">
                                    <li>
                                        <div className="round-box"><img src={require("../images/photo/studio1-1.png")} alt=""/></div>
                                        <div className="img-bt-txt">
                                            <p className="img-tit">에어컨</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="round-box"><img src={require("../images/photo/studio1-2.png")} alt=""/></div>
                                        <div className="img-bt-txt">
                                            <p className="img-tit">패널</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="round-box"><img src={require("../images/photo/studio1-3.png")} alt=""/></div>
                                        <div className="img-bt-txt">
                                            <p className="img-tit">호리존 입구</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="round-box"><img src={require("../images/photo/studio1-4.png")} alt=""/></div>
                                        <div className="img-bt-txt">
                                            <p className="img-tit">호리존 컨트롤룸 방향</p>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                    </div>
                </div>

                <div className="studio-con">
                    <div className="con-width">
                        <h3 data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000">컨트롤 룸</h3>
                        <p className="sub-txt" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">실시간 멀티뷰와 실내 음향 환경조성으로<br/>최상의 모니터링 환경을 제공합니다.</p>
                        <div className="studio-img-area" data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                            <div className="round-box wide">
                                <div><img src={require("../images/photo/studio2.png")} alt=""/></div>
                            </div>
                                <ul className="img-list">
                                    <li>
                                        <div className="round-box"><img src={require("../images/photo/studio2-1.png")} alt=""/></div>
                                        <div className="img-bt-txt">
                                            <p className="img-tit">룸어쿠스틱</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="round-box"><img src={require("../images/photo/studio2-2.png")} alt=""/></div>
                                        <div className="img-bt-txt">
                                            <p className="img-tit">소파</p>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                    </div>
                </div>

                <div className="studio-con">
                    <div className="con-width">
                        <h3 data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000">기타시설</h3>
                        <p className="sub-txt" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">주차장 및 넓은 공간이 갖춰져 있어<br/>쾌적한 기타 시설로 촬영을 진행할 수 있습니다.</p>
                        <div className="studio-img-area" data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                            <ul className="img-list">
                                <li>
                                    <div className="round-box"><img src={require("../images/photo/studio3-1.png")} alt=""/></div>
                                    <div className="img-bt-txt">
                                        <p className="img-tit">스튜디오 로고</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="round-box"><img src={require("../images/photo/studio3-2.png")} alt=""/></div>
                                    <div className="img-bt-txt">
                                        <p className="img-tit">스튜디오 복도</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="round-box"><img src={require("../images/photo/studio3-3.png")} alt=""/></div>
                                    <div className="img-bt-txt">
                                        <p className="img-tit">입구 복도</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="round-box"><img src={require("../images/photo/studio3-4.png")} alt=""/></div>
                                    <div className="img-bt-txt">
                                        <p className="img-tit">화장실 입구</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="studio-con">
                    <div className="con-width">
                        <h3 data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000">촬영장비</h3>
                        <p className="sub-txt" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">스튜디오 이용시 장비 대여 서비스를 진행하고 있습니다.<br/>다양한 유/무료 장비들이 구비되어 있으니 예약시 신청 가능합니다.</p>
                        <div className="studio-img-area" data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                            <ul className="img-list col4">
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc1.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">24인치 프롬프터</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc2.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">Apture nova 300c</p>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc3.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">Apture LS 600d</p>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc4.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">C 스탠드</p>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc5.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">원형 돔 디퓨저</p>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc6.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">무게추</p>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc7.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">베이비 붐 스탠드</p>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc8.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">비디오 헤드 / 삼각대 세트</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc9.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">삼성 55인치 TV</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc10.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">삼성 더 세로 TV</p>
                                            </div>
                                        </div>
                                        
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc11.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">스팀 다리미</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc12.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">접이식 의자</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc13.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">접이식 테이블</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc14.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">젬볼 디퓨저</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc15.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">조명 스탠드</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc16.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">카메라 플레이트</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc17.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">크로마키 천 세트</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc18.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">클램프</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc19.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">무선 프리젠터</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="round-box">
                                        <div>
                                            <img src={require("../images/photo/studio-etc20.png")} alt=""/>
                                            <div className="img-bt-txt">
                                                <p className="img-tit">흰색 배경 천 세트</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                    </div>
                </div>

            </div>
            </div>


		<Footer isSection={false}/>

        </>
    )


}

export default Studio