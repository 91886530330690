
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


import Footer from "../includes/Footer"

function About(props){

	useEffect(()=>{

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
		
		if(typeof props.onBind == 'function')
        {
          props.onBind();
        }

	}, []);


    useEffect(() => {
        AOS.init();
    })

    return (
        <>
            <div className="sub-container">
			<div className="sub-top about">
				<div className="sub-top-inner">
					<h2 data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="500">ABOUT</h2>
				</div>
			</div>

			<div className="sub-con">
				<div className="con-width">
					<div className="about-con">
						<h3 data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">성공적인 콘텐츠는 성공적으로 이끌 수 있는<br/>제작사를 만나는 것부터 시작합니다.</h3>
						<p className="sub-txt"  data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-out-cubic" data-aos-duration="1000">아비브 플레이는 콘텐츠 제작의 불필요한 소통과 과정을 해결하고 <span className="pc-block">고객을 위한 최적의 솔루션을 제공합니다.</span></p>
						<div className="round-box"  data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000"><img src={require("../images/photo/about-img.png")} alt=""/></div>
					</div>
					<div className="about-con">
						<h3 data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000">우리는 이런 일을 합니다.</h3>
						<p className="sub-txt" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">촬영부터 제작, 믹싱까지 영상 프로덕션 전 영역 진행으로<br/>다양한 프로젝트를 진행하며 메이저 스튜디오로 도약하고 있습니다.</p>
						<div className="about-box-list" data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
							<div className="box">
								<div className="box-inner">
									<i className="about-icon video"></i>
									<p className="box-tit">영상제작</p>
									<p className="box-txt">기업 / 인터뷰 / 브랜딩 / 모션그래픽 / 믹싱, 마스터링 / 사운드 디자인 / etc.</p>
									<div className="box-bt">
										<p>기획-촬영-편집까지 모든 과정을 컨트롤 할 수 있는 전문화 된 플레이어가 효율적인 프로세스를 제시하고 원하는 결과를 제공합니다.</p>	
									</div>
								</div>
							</div>
							<div className="box">
								<div className="box-inner">
									<i className="about-icon virtual"></i>
									<p className="box-tit">버츄얼 콘텐츠</p>
									<p className="box-txt">3D CGI / VFX / etc.</p>
									<div className="box-bt">
										<p>기술력이 내재화된 버츄얼 스튜디오로 3D CGI, VFX등 3D 영상제작 기술을 콘텐츠에 결합하여 크리에이티브한 결과물을 제공합니다.</p>	
									</div>
								</div>
							</div>
							<div className="box">
								<div className="box-inner">
									<i className="about-icon live"></i>
									<p className="box-tit">라이브 스트리밍</p>
									<p className="box-txt">콘퍼런스 / 웨비나 / 프로모션 이벤트 / 하이브리드 / 기업 내 행사 / etc.</p>
									<div className="box-bt">
										<p>풍부한 경험을 기반으로 안정적이며 효율적인 시스템 구성을 제안드리며, 전문성과 기술력으로 인터렉티브한 라이브 방송을 연출해드립니다.</p>	
									</div>
								</div>
							</div>
							<div className="box">
								<div className="box-inner">
									<i className="about-icon studio"></i>
									<p className="box-tit">아비브 스튜디오</p>
									<p className="box-txt">호리존 / 크로마키 / 컨트롤 룸</p>
									<div className="box-bt">
										<p>크로마키 호리존 및 실시간 라이브 송출과 프리뷰 / 모니터링 콘솔을 갖춘 50평대 규모의 스튜디오</p>	
									</div>
								</div>
							</div>
							<div className="box">
								<div className="box-inner">
									<i className="about-icon sound"></i>
									<p className="box-tit">사운드 디자인</p>
									<p className="box-txt">게임 사운드 / 광고 음악 / 믹싱 / 마스터링</p>
									<div className="box-bt">
										<p>엔지니어가 방송 콘텐츠, 영화, 게임, 임베디드 사운드 등 수 많은 믹싱 / 마스터링, 포스트 경험으로 콘텐츠의 사운드 퀄리티를 올려드립니다.</p>	
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="about-bt">
					<h3 className="about-bt-tit" data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000">OUR TEAM</h3>
					<div className="bt-con" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
						<div className="bt-con-inner">
							<p className="cir-txt"><span>Planning</span></p>
							<div className="bt-txt">
								<p>콘텐츠 기획</p>
								<p>프로젝트 매니징</p>
							</div>
						</div>
						<div className="bt-con-inner">
							<p className="cir-txt"><span>Production</span></p>
							<div className="bt-txt">
								<p>영상 촬영/편집</p>
								<p>사운드 디자인</p>
								<p>라이브 스트리밍</p>
							</div>
						</div>
						<div className="bt-con-inner">
							<p className="cir-txt"><span>Studio</span></p>
						</div>
					</div>
				</div>
			</div>
		</div>


		<Footer isSection={false}/>

        </>
    )


}

export default About