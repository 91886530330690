import '../css/lib/mCustomScrollbar.css';
// import '../_css/common.css';
// import '../_css/layout.css';
// import '../css/media.css';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'


import "./css/lib/jquery.datetimepicker.min.css";

//V2 RENEWAL
import "./css/layout.css";
import "./css/common.css";
import "./css/media.css";
import "./css/mod.css";


import React, {useState, useEffect, useRef } from 'react';
import $ from "jquery";

import Header from './includes/Header';
import Footer from './includes/Footer';

import FloatingContact from './components/FloatingContact';

import App from './App';

import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import 'react-custom-alert/dist/index.css'; // import css file from root.

import { AlertContainer } from 'react-custom-alert';




let Layout = function({match}) { 
	const headerRef = useRef();

	let onRoute = () => {

		
		headerRef.current.setMenuOpen(false);
		
		const script2 = document.createElement('script');
		script2.text = `
		 if (!wcs_add) var wcs_add = {};
		 wcs_add["wa"] = "s_388a7e8ae03d"; 
		 if (!_nasa) var _nasa = {};
		 if (window.wcs) { wcs.inflow();         
		 wcs_do(_nasa); } var _nasa = {};
		 `;

		
	}

	  function bindEffect(){
		//   $("a:not(.bind)").hover(function(){
		// 		$(".pointer").addClass("hover");
		// 		if($(this).parents().hasClass("logo")){
		// 			$(".pointer .hv").find("span").text("home");
		// 		} else if($(this).hasClass("btn-contact")) {
		// 			$(".pointer .hv").find("span").text("aviv");
		// 		} else {
		// 			$(".pointer .hv").find("span").text("view");
		// 		}
		// 	},function(){
		// 		$(".pointer").removeClass("hover");
		// 	}).addClass("bind");

	  }



	var fixScroll = 0;
	$(window).on("scroll", function(event){
			var scroll = $(this).scrollTop();
			if (scroll > 0){
			//스크롤 높이 설정
						$(".gnb-chk").addClass("on");
						$(".works-header").addClass("white");

			}
			else {
						$(".gnb-chk").removeClass("on");
						$(".works-header").removeClass("white");
			}
			fixScroll = scroll;
	});



	$("body").on("mousemove", function(e) {
		//client_x = e.clientX;
		//	client_y = e.clientY;
			// $(".pointer").css({
			// 	top:e.clientY,
			// 	left:e.clientX
			// });
	});

	bindEffect();


		//const location = useLocation();

		console.log(match);


	  return (

		<div id="wrapper" className="on">
			<Router>
				<Header pure={match} ref={headerRef}/>
				<App onBind={bindEffect} onRoute={onRoute}/>
				<FloatingContact/>
			</Router>

			<AlertContainer floatingTime={1500} />
		</div>
	  );

}

export default Layout;
