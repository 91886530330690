import { ReactComponent as Loading } from "../images/loading.svg";
import React, {useEffect, useState} from 'react';
import $ from "jquery";
import ColoredScrollbars from '../components/CustomScrollBar';
import axios from '../components/Api';
import {useParams} from 'react-router-dom'

import LoadingSpinner from "../components/Loading";
import YouTubeVideo from "../components/YoutubeEmbed";

import DelayLink from "../components/DelayLink";

function Work (props) {

    const params = useParams();

	let [workListIsLoading, setWorkListIsLoading] = useState(false);
    let [portfolio, setPortfolioData] = useState();
    let first = false;

    let players = [];
    let [onIndex, setOnIndex] = useState(0);

    // let state = {
    //     portfolio:null
    // }

    useEffect(()=>{

        console.log("render");

        if(typeof props.onBind == 'function')
        {
          props.onBind();
        }

    }, [portfolio]);

    function loadPortfolioData(id) {
        let t = this;

        console.log('load port');

        //https://www.youtube.com/embed/2wIUEo6kXwI


        setWorkListIsLoading((newState) => {

            // if(portfolio)
            // {
            //     console.log("load portfolio");
            //     return;
            // }
            

            
            var apiParams = new URLSearchParams();
            apiParams.append('order', 'asc');
            
            axios.post("https://avivplay.com/api/get/portfolio/"+id, apiParams)
            .then((res)=>{

                res = res.data;
                //console.log(res);


                if(res.resCode == '0')
                {
                    let resData= res.result;

                    //t.state.portfolioList = resList;
                    //setPortfolioList(resList);

                    setWorkListIsLoading((newState) => {

                        console.log(resData);
                        
                        setPortfolioData(resData);

                        return false;
                    });

                }
                else
                {
                    console.log("failed");
                    alert(res.resMsg);
                    console.log(res);
                }

            })
            .catch(()=>{
                console.log("catch exception")

            })

            return true;
        });



    }

    useEffect(() => {

		loadPortfolioData(params.id);

        if(window.YPLAYER)
        {
            window.YPLAYER = {};
        }

        return () => {
            console.log("out");
        };

	}, [params.id]);

    //선택 비디오 초기화
    useEffect(() => {
        
        console.log("indexing");
        
    }, [onIndex])

        return (
            <div id="contents">
			    <div className="contents-align">
                    <div className="con-wrap">
                        <div className="divide-left">
                            <div className="list-videoCon-wrap">

                                { workListIsLoading &&  <LoadingSpinner full={true}/> }

                                {
                                    portfolio ?


                                    portfolio.workList.map((work, index) => (
                                        

                                        <div key={index} className={"list-videoCon-item" + (index == onIndex ? " on":" off")} onClick={(e)=>{
                                            //let target = $(".list-videoCon-item:eq("+index+")");

                                            //if(target.hasClass("on"))
                                            if(index == onIndex)
                                            {
                                                //target.removeClass("on");
                                                setOnIndex(-1);
                                            }
                                            else
                                            {
                                                // portfolio.workList.forEach(function(_work){
                                                //     if(_work.seed != work.seed)
                                                //     {
                                                //         work.on = false;
                                                //     }
                                                // })

                                                //$(".list-videoCon-item.on").removeClass("on");
                                                //target.addClass("on");
                                                
                                                setOnIndex(index);
                                            }
                                        }}>
                                            <YouTubeVideo title={work.title} key={index} on={index == onIndex} url={work.dest_video} no={index} />
                                        </div>

                                        ))


                                    : ""
                                }

                                {portfolio && portfolio.workList.length == 0 ? <div className="emptyRow"><span>NO VIDEOS</span></div> : ""}
                            </div>
                        </div>
                        <div className="divide-right">
                        { workListIsLoading &&  <LoadingSpinner full={true}/> }

                        {
                            portfolio ?
                            <>
                                <ColoredScrollbars  className="pj-scroll-area scroll-area">
                                    <div className="scroll-pdCon">

                                        <div className="pj-info-top">
                                            <h1>{portfolio.name_full}</h1>
                                            <div className="pj-info-detail">
                                                <div className="detail-cell"><span className="cell-label">client</span><span>{portfolio.client}</span></div>
                                                <div className="detail-cell"><span className="cell-label">date</span><span>{portfolio.workdate}</span></div>
                                            </div>
                                        </div>

                                        <div className="pj-info-subs" dangerouslySetInnerHTML={ {__html:(portfolio.detail_text ? portfolio.detail_text : "")} } />


                                    </div>
                                </ColoredScrollbars>


                                <div className="pj-info-bottom">

                                        {portfolio.seed_1 && <>
                                            <div className="pj-prev-area">
                                                <DelayLink to={"/Works/" + portfolio.seed_1}

                                                        delay={500}

                                                        whenDelayStart={ ()=> {
                
                                                            //$('body').addClass("blur");
                
                                                        } }
                                                        whenDelayEnd={ ()=> {

                                                            setOnIndex(0);
                
                                                            //$('body').removeClass("blur");
                
                                                        } }
                                                >
                                                    <span className="ico-direction prev"></span>
                                                    <span className="dir-txt">prev</span>
                                                    <span className="pj-name"><span>{portfolio.name_1}</span></span>

                                                </DelayLink>
                                            </div>
                                        </>}

                                    <div className="pj-center-area"><DelayLink to={"/Works"} className="ico-list"></DelayLink></div>

                                    {portfolio.seed_2 && <>
                                            <div className="pj-next-area">
                                                <DelayLink to={"/Works/" + portfolio.seed_2}

                                                        delay={500}

                                                        whenDelayStart={ ()=> {
                
                                                            //$('body').addClass("blur");
                
                                                        } }
                                                        whenDelayEnd={ ()=> {

                                                            setOnIndex(0);
                
                                                            //$('body').removeClass("blur");
                
                                                        } }
                                                >
                                                    <span className="dir-txt">next</span>
                                                    <span className="pj-name"><span>{portfolio.name_2}</span></span>
                                                    <span className="ico-direction next"></span>

                                                </DelayLink>
                                            </div>
                                    </>}


                                </div>
                            </> : ""
                         }

                        </div>
                    </div>
                </div>
            </div>
        );

}

export default Work;
