import React, {useState, useEffect, forwardRef} from "react"

const CustomSelect = forwardRef((props, ref) => {

    
    let [selected, setSelected] = useState(props.default);
    let [active, setActive] = useState(false);
    
    useEffect(()=>{


    }, [selected, ref])
    
    let selectOne = (value) => {

        setSelected(value)
        setActive(false)
    }

    return (
        <>
            <div className="custom-select">
                <select ref={ref} name={props.name}  value={selected}>
                    <option value="">{props.default}</option>
                    {
                        props.itemList.map((item, index) => (

                            <>
                                <option value={item} key={index}>{item}</option>
                            </>
                        ))
                    }
                </select>

                <div className={"select-selected" + (active ? " select-arrow-active" : "")} onClick={()=>{setActive(!active)}}>{selected}</div>

                <div className={"select-items" + (active ? "" : " select-hide")}>

                    {
                        props.itemList.map((item, index) => (

                            <>
                                <div className={item == selected ? "same-as-selected" : ""} key={index} onClick={()=>{selectOne(item)}}>{item}</div>
                            </>
                        ))
                    }

                </div>
            </div>
        </>
            
        )

})



export default CustomSelect;