
import React, {useEffect} from 'react';
import Form from '../components/Form';

import AOS from "aos";
import "aos/dist/aos.css";

function Contact(props) {
   
	useEffect(()=>{

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
		
		if(typeof props.onBind == 'function')
        {
          props.onBind();
        }

	}, []);


    useEffect(() => {
        AOS.init();

		//console.log(customSelectify)
    })

  return (
	<>
		<div class="sub-container contact">

			<div class="contents-align black">		
				<div class="contact-wrap">



					<div class="form-top" data-aos="fade-up" data-aos-delay="0" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
						<div class="form-top-tit">
							<h1>Play with us!</h1>
							<p>Make a valuable project with AVIV PLAYER!</p>
						</div>
						<div class="form-top-btn"><a href="/archive/AVIV Play_서비스 소개서.pdf" download target="_blank" class="line-btn">회사소개서<i class="ico-arrow"></i></a></div>
					</div>

	


					<Form/>

						
				</div>
			</div>			
		</div>
		
	</>
  );
}

export default Contact;
