import {ReactComponent as Loading} from "../../images/loading.svg";
import React, {useState, useEffect} from 'react';
import $ from "jquery";
import axios from '../../components/Api';

import DelayLink from "../../components/DelayLink";

import LoadingSpinner from "../../components/Loading";

import Footer from "../includes/Footer"


//slick
import Slider from "react-slick";
import '../css/lib/slick.css';


//swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Mousewheel, EffectFade } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


import Fullpage from "../components/Fullpage";



function useScroll(recall, onBind, onMount2, onResizeCallback, onScroll){

	const MODE_NOT_INIT = -1, MODE_DESKTOP = 0, MODE_MOBILE = 1

	let heightChk = false;
	let widChk = false;
	let marginCon = 0;
	let pjBg = null;
	let heightChk1 = false;
	let heightChk2 = false;

	let resizeProcessing = false;

	let mode = MODE_NOT_INIT

	let timeoutHandle = null;

	//페이지 진입시에만 붙이는 scroll 이벤트 리스너, 메인에서만 사용하기 위해
	let onResize = () => {

		if(resizeProcessing) return;

		resizeProcessing = true;

		projectOn();
		
		if(window.innerWidth > 1024)
		{
			if(mode != MODE_DESKTOP)
			{
				
				animateIntro();
				onResizeCallback(true)	
				mode = MODE_DESKTOP
			}
		}
		else
		{
			if(mode != MODE_MOBILE)
			{
				
				animateIntro();
				onResizeCallback(false)
				mode = MODE_MOBILE
			}
		}
		
		resizeProcessing = false;

	}

	//최초 페이지 바인딩 시에만 실행하기 위해
	if(typeof onBind === 'function')
	{
		onBind();
	}

	let projectOn = () => {

		
	}
	
	
	//랜더링할때마다 실행
	useEffect(() => {

		// setTimeout(function(){
		// 	$(".works-con > ul > li").not(".fadeInLeft").addClass("fadeInLeft");

		// }, 100);
	});




	let state = {
		mouseFuncFlag:false,
		mouseStatus:false,
		myReq:null,
		countTxt:0,
		marginCon:50
	};


	let txtShow = () => {

			$(".tit-line-outer").find(".tit-line:eq("+state.countTxt+")").addClass("show");
			$(".tit-line-outer").find(".tit-line:not(:eq("+state.countTxt+"))").removeClass("show");

			state.countTxt++;
			if(state.countTxt === $(".tit-line-outer").find(".tit-line").length+1){
				state.countTxt=0;
			}
	}

	let animateIntro = () => {
		

			if(timeoutHandle)
			{
				clearTimeout(timeoutHandle)
				timeoutHandle = null
			}

			//이미 로딩이 끝남
			if(!$('#wrapper').hasClass("on"))
			{
				recall(false);
				(function startIntro(){
					txtShow();
					timeoutHandle = setTimeout(startIntro,2000);
				})();


				setTimeout(function(){
					for(var i=0;i<=$(".showEft").length;i++){
						(function(i){
						setTimeout(function(){
							$(".showEft").eq(i).addClass("on");
						},i*165);
						}(i));
					};
				},0);

			} else {


				$(".loading").on("transitionend",function(){

					recall(false);

					(function startIntro(){
						txtShow();
						if($(".tit-line-outer").length > 0)
						{
							timeoutHandle = setTimeout(startIntro,2000);
						}
					})();


					setTimeout(function(){
					for(var i=0;i<=$(".showEft").length;i++){
						(function(i){
						setTimeout(function(){
							$(".showEft").eq(i).addClass("on");
						},i*165);
						}(i));
					};
					},0);


					
				});

			}

	}

	//최초 1회 실행
	useEffect(() => {

		onResize();
		
		if(typeof onMount2 === 'function')
		{
			onMount2();
		}


		   onMountInit();

		  setTimeout(function(){$("#wrapper").removeClass("on");},500);

		  function onMountInit() {

			//   console.log("on mount main");


			// let vid = document.getElementById("video-reel");
			// vid.play();

			// window.addEventListener("scroll",function(){
			// 	onResize();
			//  });


			//  $(window).resize(function(){
			// 	onResize();
			// });




			// $(".list-item-focus").on({
			// 	mouseenter : function(){
			// 		$(this).parents(".list-item").addClass("on");
			// 		state.mouseFuncFlag = true;
			// 	},
			// 	mouseleave : function(){
			// 		$(this).parents(".list-item").removeClass("on");
			// 		state.mouseFuncFlag = false;
			// 	}
			// });





			/* 변경 필요 */
			$(".list-work").find("a").on({
				mouseenter:function(){
					$(".list-work a").removeClass("hover");
					$(this).addClass("hover");
				}
			});
			/* 변경 필요 */

		  }

		  

		// window.addEventListener("scroll", onScroll);
		window.addEventListener("resize", onResize);
		
		window.addEventListener("wheel", onScroll);
		window.addEventListener("touchmove", onScroll);
		
		//onWillUnmount
		return () => {
		  window.removeEventListener("wheel", onScroll);
		  window.removeEventListener("touchmove", onScroll);
		  window.removeEventListener("resize", onResize);

			//$("#wrapper").removeClass("black");

		};

	}, []);
}


function Loader(props)
{


	return (
		props.isLoading && <div className="loading" ><Loading/></div>

	)
}

function Main (props) {
	
	let state = {
		mouseFuncFlag:false,
		mouseStatus:false,
		myReq:null,
		x:0,
		y:0,
		mx:0,
		my:0,
		speed:0.03,
		loaded:0,
		perpage:5
	};


	
	

    const setSlider = (idx, slider) => {
	
	}


	const settings = (idx) => {


			
			return {
				dots: false,
				arrows: false,
				autoplay: true,
				rtl:idx%2 == 1,
				autoplaySpeed: 0,
				infinite: true,
				speed: 5000,
				slidesToShow: 8,
				slidesToScroll: 1,
				responsive: [
				  {
					breakpoint: 1024,
					settings: {
					  slidesToShow: 7,
					}
				  },
				  {
					breakpoint: 768,
					settings: {
					  slidesToShow: 5,
					  slidesToScroll: 1
					}
				  },
				  {
					breakpoint: 480,
					settings: {
					  slidesToShow: 3,
					  slidesToScroll: 1
					}
				  }
				],
				onInit:()=>{
					
				}
				

			}

		}
		
		


	


	let [isLoading, setIsLoading] = useState(true);
	let [workListIsLoading, setWorkListIsLoading] = useState(false);
	let [swiperOn, setSwiperOn] = useState(false);
	let [wheelStateBelongFp, setWheelStateBelongFp] = useState(true)
	let {workList, setWorkList, workListPage, setWorkListPage}= props;//useState([]);
	let [belongList, setBelongList] = useState([])
	let [belong, setBelong] = useState("0")
	const [mutedMain, setMutedMain] = useState(true)
	const [page, setPage] = useState(1)

	let addLoaded = (idx, total) => {

		state.loaded++;
		
		workList[idx].loaded = workList[idx].loaded ? workList[idx].loaded+1 : 1;
		workList[idx].total = total;

		if(workList[idx].loaded >= total)
		{
			$(".works-con > ul > li:eq("+idx+")").addClass("fadeInLeft");
		}

		if(workList.filter((item, idx)=>{ return (!item.total || item.total == item.loaded) }).length >= workList.length) {
			
				setWorkListIsLoading(true)

				setTimeout(function(){


					if(window.innerWidth > 1024) {
						if( $.fn.fullpage)  
						{
							$.fn.fullpage.reBuild()
						}

					}

					 //$(".works-con > ul > li").not(".fadeInLeft").addClass("fadeInLeft");
					 setWorkListIsLoading(false)

				}, 100);
				
			

		}

	}

	// let {belongList, belong} = {belongList:[], belong:'0'}

	// let setBelong = (v) => {
	// 	belong = v
	// }

	// let setBelongList = (v) => {
	// 	belongList = v
	// }




	//let [workListPage, setWorkListPage] = useState(1);
		
	let [slides, setSlides] = useState((()=>{let o = new Array(5); o.state = 0; return o})());
	let [inVideo, setInVideo] = useState(false)

	useEffect(()=>{
		//show loading

		console.log("loading..");

	}, [workListIsLoading, belongList]);


	useEffect(()=>{
		

	}, [workList, page, inVideo]);


	useEffect(()=>{
		

		loadWorkPage(null, true)


	}, [belong]);


	useEffect(()=>{

	}, [swiperOn])

	useEffect(()=>{

		for(let slide of slides)
		{

			if(!slide || !slide.mousewheel) continue;
			
			if(wheelStateBelongFp)
			{
				slide.mousewheel.disable();
				
			}
			else
			{
				slide.mousewheel.enable();
			}

		}

	}, [wheelStateBelongFp])


	function loadBelongList(callback){

		var apiParams = new URLSearchParams();

		if(workListIsLoading){
			return true;
		}

		setWorkListIsLoading(true)

		axios.post("/api/get/belong", apiParams)
		.then((res)=>{

			res = res.data;

			if(res.resCode === 0)
			{
				let resList = res.result;
				
				setBelongList(resList)
				setWorkListIsLoading(false);


				if(typeof callback === 'function')
				{
					callback();
				}
			}
			else
			{
				console.log("failed");
			}
		})
		.catch(()=>{
			console.log("catch exception")
		})

		// console.log("after set")
		return true;

	}

	function loadWorkPage(e, renew) {


		if(e)
		{
			e.preventDefault();
		}

		if(workListIsLoading){
			return true;
		}

		if(renew)
		{
			setWorkListPage(1)
		}

		setWorkListIsLoading(true);

		var apiParams = new URLSearchParams();
		apiParams.append('pagenum', renew ? 1 : workListPage);
		apiParams.append('perpage', state.perpage);
		
		apiParams.append('order', 'asc');
		apiParams.append('belong', belong);

		axios.post("/api/get/work", apiParams)
		.then((res)=>{

			if(workListPage != 1)
			{
				setTimeout(()=>{

					let top = window.scrollY + 500;

					window.scrollTo({
						top: top,
						behavior: 'smooth',
					})
				}, 500);

			}

			//console.log("axios worklist get:");
			//console.log(res);

			$(".bottom-area .btn").css({display:"inline-block"});

			res = res.data;

			if(res.resCode === 0)
			{
				let resList = res.result;


				if(resList.length < state.perpage)
				{
					setWorkListPage((page) => { return 0});

					$(".bottom-area .btn").hide();
				}
				else
				{
					setWorkListPage((page) => { return page*1+1});
				}


				setWorkList(renew ? resList : workList.concat(resList));

				setWorkListIsLoading(false);
			}
			else
			{
				console.log("failed");
				
			}
		})
		.catch(()=>{
			console.log("catch exception")
			$(".bottom-area .btn").css({display:"inline-block"});
		})
		
		


            


	};




	const swiperSlideSet = (no, effect, wheel) => {
	
	
		return {
			// direction: "vertical",
			draggable: false,
			slidesPerView: 1,
			spaceBetween: 0,
			freeMode: false,
			speed: 700,
			effect: effect,
			mousewheel: true,
			onAfterInit:(o)=>{

				slides.state++;


				slides[no] = o;
				slides[no].currentPage = 0
				setSlides(slides);

				
			},
			onSlideChange:(o)=>{

				var idx = o.activeIndex;
				slides[no].pageCount = o.slides.length
				slides[no].swiping = true
				setSlides(slides);
				// 처음과 마지막 슬라이드가 아닐경우 fullpage전환 막기
				//if(o.activeIndex != 0 && idx != o.slides.length) $.fn.fullpage.setAllowScrolling(false);
				//if(o.slides.length == 2 && idx == 0) $.fn.fullpage.setAllowScrolling(false) //슬라이드가 2개밖에 없을때
				// console.log('즉시 : ' + idx);
			},
			onSlideChangeTransitionEnd:(o)=>{
				var idx = o.activeIndex;
				// 처음과 마지막 슬라이드일 경우 fullpage전환 풀기
				//if(idx == 0 || idx >= o.slides.length-1) $.fn.fullpage.setAllowScrolling(true);
				// console.log('전환후 : ' + idx);
				console.log("end")
				slides[no].swiping = false
				slides[no].currentPage = idx
				setSlides(slides);

				if(no == 0)
				{
					setInVideo(idx == 1)
				}
			},
		} 
		
		
	}

	const swiperWheelOn = () => {
		
		// $('#fullpage').off('scroll mousewheel');      
		if(!$(".fp-completely .swiper-wrapper").length > 0) $('#fullpage').on('touchmove'); // 모바일분기
		if($(".section.active").length == 0) $.fn.fullpage.setAllowScrolling(true); // 슬라이드 섹션을 벗어나면 휠풀어주기
		
		
		
	}
	

	let swiper
	// New attacted
	let fullpageInitialized = false;

	let slidesEffects = Array(5).fill().map((v, i) => (i == 0 ? 'slides' : 'fade'));
	let fProcessing = false;

	slides.state = 0;

	if(!window.currentPage)
		window.currentPage = 1
	
	

	const DIR_UP = 0, DIR_DOWN = 1

	const onScroll = (event) => {
		
		// setMutedMain(false)

		let direction = DIR_UP

		if (event.wheelDelta) {
			
			direction = event.wheelDelta > 0 ? DIR_UP : DIR_DOWN
		}
		else if(event.deltaY)
		{
			direction = event.deltaY < 0 ? DIR_UP : DIR_DOWN
		}
		else
		{
			return;
		}

		if($.fn.fullpage && $.fn.fullpage.setAllowScrolling)
		{
			if(!window.currentPage || window.currentPage < 6)
			{
				if(slides[window.currentPage-1])
				{
					if(slides[window.currentPage-1].swiping)
					{
						$.fn.fullpage.setAllowScrolling(false);
						return;
					}
					else
					{
	
					}
					
					if(direction == DIR_UP)
					{
						if(slides[window.currentPage-1].currentPage == 0)
						{
							$.fn.fullpage.setAllowScrolling(true);
						}
						else
						{
							$.fn.fullpage.setAllowScrolling(false);
						}
					}
					else
					{
						if(slides[window.currentPage-1].currentPage == slides[window.currentPage-1].pageCount-1)
						{
							$.fn.fullpage.setAllowScrolling(true);
						}
						else
						{
							$.fn.fullpage.setAllowScrolling(false);
						}
					}
				}
				else
				{
					$.fn.fullpage.setAllowScrolling(false);
				}
	
				
			}
			else
			{
				$.fn.fullpage.setAllowScrolling(true);
	
			}

		}
		
		
		

	}




	//props.setScroll();//useScroll();
	useScroll(setIsLoading, props.onBind, function(){

		loadBelongList(()=>{

			// if(workListPage === 1)
			// {
			// 	loadWorkPage();
			// }

		})


	}, setSwiperOn, onScroll);


	const onLeave = () => {

		// swiperWheelOff();
		setWheelStateBelongFp(true)
	}



	const afterRender = (origin, destination, direction, trigger) => {

		fullpageInitialized = true;
		//swiperWheelOn();
		
		$.fn.fullpage.setAllowScrolling(false);

		// swiperSet();
		setWheelStateBelongFp(false)
		//$(".swiper-container").on("mousewheel")

		// if(destination < 6)
		// {
		// 	$.fn.fullpage.setAllowScrolling(false);
		// }
		// else
		// {
		// 	console.log("afterRender" + destination)
		// 	$.fn.fullpage.setAllowScrolling(true);
		// }


	}


	const afterLoad = (origin, destination, direction, trigger) => {
		// swiperWheelOn();

		setWheelStateBelongFp(false)

		window.currentPage = destination

		setPage(destination)

		if(destination < 6)
		{
			$.fn.fullpage.setAllowScrolling(false);
		}
		else
		{
			$.fn.fullpage.setAllowScrolling(true);
		}

		//$.fn.fullpage.moveTo(6)

		//$(".swiper-container").on("mousewheel")
	}

	

	const getPageSlick = (page) => {

		const result = [];

		const perpage = 8

		for(let idx = 0; idx < perpage; idx++)
		{
			result.push(<li className=""><img src={require('../images/client/client_'+((page*perpage + idx + 1)+"").padStart(2, '0')+'.png')} alt=""/></li>)
		}

		
		return result.concat(result)
	}


	  return (
		<>
			<Loader isLoading={isLoading}/>
			
			<Fullpage 
				key="1"
				onLeave={onLeave}
				afterRender={afterRender}
				afterLoad={afterLoad}
			
				>
				
				<div className="section sec1 active">

				
				  {
					swiperOn ? 
						<div>
							<Swiper 
								modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel]}

							className="visual-area swiper-container" {...swiperSlideSet(0, 'slides', !wheelStateBelongFp)}>
						
							<div className="swiper-wrapper">
							<SwiperSlide className="section1-left swiper-slide">
								<div className="hd-color">
								<div className="intro-tit">
									<div className="tit-line showEft show">
									<div className="tit">we</div>
									<div className="gra-con-wrap">
										<div className="gra-con"></div>
									</div>
									<div className="tit">play</div>
									</div>
									<div className="tit-line-outer showEft">
									<div className="tit-line">									
										<div className="tit">film</div>									
										<div className="tit">shooting</div>
										<div className="img-txt-concept"></div>									
									</div>
									<div className="tit-line">									
										<div className="tit">video</div>									
										<div className="tit">editing</div>
										<div className="img-txt-concept"></div>
									</div>
									<div className="tit-line">									
										<div className="tit">live</div>									
										<div className="tit">streaming</div>
										<div className="img-txt-concept"></div>			
									</div>
									<div className="tit-line">									
										<div className="tit">Virtual</div>									
										<div className="tit">Contents</div>
										<div className="img-txt-concept"></div>									
									</div>
									</div>
									<div className="tit-line showEft">
									<div className="tit">we're</div>
									<div className="tit">creative</div>
									<div className="tit">players.</div>
									</div>
								</div>
								</div>
								<div className="scrl-con"><span className="scrl-inner">SCROLL</span><span className="ico-scrl"></span></div>
							</SwiperSlide>
							<SwiperSlide className="section1-right swiper-slide">
								<div className="play-area-wrapper">
								<div className="background-video">
									<video autoPlay={true} loop="" muted={mutedMain || page != 1 || !inVideo} playsInline="" id="video-reel" data-keepplaying>
									<source src={require('../data/videos/bg-main-visual.mp4')}></source>
									</video>
								</div>
								<button className={"btn-sound" + (mutedMain ?  " on": "")} onClick={()=>{setMutedMain(!mutedMain)}}>소리끄기</button>
								</div>
							</SwiperSlide>
							</div>
						</Swiper>
					</div>
					: 

					<div className="visual-area swiper-container">
					<div className="swiper-wrapper">
					  <div className="section1-left swiper-slide">
						<div className="hd-color">
						  <div className="intro-tit">
							<div className="tit-line showEft show">
							  <div className="tit">we</div>
							  <div className="gra-con-wrap">
								<div className="gra-con"></div>
							  </div>
							  <div className="tit">play</div>
							</div>
							<div className="tit-line-outer showEft">
							  <div className="tit-line">									
								<div className="tit">film</div>									
								<div className="tit">shooting</div>
								<div className="img-txt-concept"></div>									
							  </div>
							  <div className="tit-line">									
								<div className="tit">video</div>									
								<div className="tit">editing</div>
								<div className="img-txt-concept"></div>
							  </div>
							  <div className="tit-line">									
								<div className="tit">live</div>									
								<div className="tit">streaming</div>
								<div className="img-txt-concept"></div>			
							  </div>
							  <div className="tit-line">									
								<div className="tit">Virtual</div>									
								<div className="tit">Contents</div>
								<div className="img-txt-concept"></div>									
							  </div>
							</div>
							<div className="tit-line showEft">
							  <div className="tit">we're</div>
							  <div className="tit">creative</div>
							  <div className="tit">players.</div>
							</div>
						  </div>
						</div>
						<div className="scrl-con"><span className="scrl-inner">SCROLL</span><span className="ico-scrl"></span></div>
					  </div>
					  <div className="section1-right swiper-slide">
						<div className="play-area-wrapper">
						  <div className="background-video">
							<video autoPlay={true} loop="" muted={mutedMain} playsInline="" id="video-reel" data-keepplaying>
							  <source src={require('../data/videos/bg-main-visual.mp4')}></source>
							</video>
						  </div>
							<button className={"btn-sound" + (mutedMain ?  " on": "")} onClick={()=>{setMutedMain(!mutedMain)}}>소리끄기</button>
						</div>
					  </div>
					</div>
				  </div>


				  }
					
				</div>
				<div className="section sec2">

				  {
					swiperOn ? 
					<div>
					<Swiper 
						modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel, EffectFade]}

					className="aviv-area swiper-container" {...swiperSlideSet(1, 'fade', !wheelStateBelongFp)}>
					<div className="swiper-wrapper">
					  <SwiperSlide className="swiper-slide bg1">
						<div className="sec2-inner">
						  <p className="tit-sub">AVIVPLAY IS</p>
						  <div className="sec2-con">
							<div className="circle-wrap">
							  <div className="circle-line cir1">
								<div>
								  <p>Creative</p>
								  <p className="">창의적인</p>
								</div>
							  </div>
							</div>
							<div className="circle-wrap">
							  <div className="circle-line cir2">
								<div>
								  <p>Hybrid</p>
								  <p className="">하이브리드</p>
								</div>
							  </div>
							 </div> 
							<div className="circle-wrap">
							  <div className="circle-line cir3">
								<div>
								  <p>Interactive</p>
								  <p className="">상호적인</p>
								</div>
							  </div>
							</div>
						  </div>
						  <p className="sub-txt">기업과 브랜드에 대한 깊은 이해를 통해 <br/><span className="txt-bold">최적의 크리에이티브 컨텐츠</span>를 제공합니다.</p>
						</div>
					  </SwiperSlide>
					  <SwiperSlide className="swiper-slide bg2">
						<div className="sec2-inner">
						  <p className="tit-sub">PROCESS</p>
						  <div className="sec2-con">
							<ul className="process">
							  <li className="around">
								<div>
								  <p className="num">01</p>
								  <p className="small">프로젝트 의뢰</p>
								</div>
							  </li>
							  <li className="circle-line">
								<div>
								  <p className="num">02</p>
								  <p className="middle">미팅</p>
								  <p className="small">상담<br/>제안·견적서 제시</p>
								</div>
							  </li>
							  <li className="circle-line">
								<div>
								  <p className="num">03</p>
								  <p className="middle">계약</p>
								  <p className="small">계약서 작성</p>
								</div>
							  </li>
							</ul>
							<ul className="process process2">
							  <li className="circle-line">
								<div>
								  <p className="num">04</p>
								  <p className="middle">프리 프로덕션</p>
								  <p className="small">기획<br/>콘텐츠 방향성 수립<br/>팀 빌딩</p>
								</div>
							  </li>
							  <li className="circle-line">
								<div>
								  <p className="num">05</p>
								  <p className="middle">프로덕션</p>
								  <p className="small">촬영·편집<br/>디자인·CG 제작</p>
								</div>
							  </li>
							  <li className="circle-line">
								<div>
								  <p className="num">06</p>
								  <p className="middle">포스트 프로덕션</p>
								  <p className="small">DI(색보정)·종합 편집<br/>사운드 디자인<br/>믹싱·마스터링<br/>시사·검수</p>
								</div>
							  </li>
							  <li className="around blue">
								<div>
								  <p className="num">07</p>
								  <p className="small">납품</p>
								</div>
							  </li>
							</ul>
						  </div>
						  <p className="sub-txt"><span className="m-block">프로젝트 규모와 일정에 따라 프로세스를 </span><span className="txt-bold">효율적으로 제시</span>해 드리고<br/><span className="txt-bold">최적의 콘텐츠를 제작</span> 가능합니다.</p>
						</div>
					  </SwiperSlide>
					</div>

				  </Swiper>
				</div> : 

					<div 

					className="aviv-area swiper-container">
					<div className="swiper-wrapper">
					<div className="swiper-slide bg1">
					<div className="sec2-inner">
					<p className="tit-sub">AVIVPLAY IS</p>
					<div className="sec2-con">
						<div className="circle-wrap">
						<div className="circle-line cir1">
							<div>
							<p>Creative</p>
							<p className="">창의적인</p>
							</div>
						</div>
						</div>
						<div className="circle-wrap">
						<div className="circle-line cir2">
							<div>
							<p>Hybrid</p>
							<p className="">하이브리드</p>
							</div>
						</div>
						</div> 
						<div className="circle-wrap">
						<div className="circle-line cir3">
							<div>
							<p>Interactive</p>
							<p className="">상호적인</p>
							</div>
						</div>
						</div>
					</div>
					<p className="sub-txt">기업과 브랜드에 대한 깊은 이해를 통해 <br/><span className="txt-bold">최적의 크리에이티브 컨텐츠</span>를 제공합니다.</p>
					</div>
					</div>
					<div className="swiper-slide bg2">
					<div className="sec2-inner">
					<p className="tit-sub">PROCESS</p>
					<div className="sec2-con">
						<ul className="process">
						<li className="around">
							<div>
							<p className="num">01</p>
							<p className="small">프로젝트 의뢰</p>
							</div>
						</li>
						<li className="circle-line">
							<div>
							<p className="num">02</p>
							<p className="middle">미팅</p>
							<p className="small">상담<br/>제안·견적서 제시</p>
							</div>
						</li>
						<li className="circle-line">
							<div>
							<p className="num">03</p>
							<p className="middle">계약</p>
							<p className="small">계약서 작성</p>
							</div>
						</li>
						</ul>
						<ul className="process process2">
						<li className="circle-line">
							<div>
							<p className="num">04</p>
							<p className="middle">프리 프로덕션</p>
							<p className="small">기획<br/>콘텐츠 방향성 수립<br/>팀 빌딩</p>
							</div>
						</li>
						<li className="circle-line">
							<div>
							<p className="num">05</p>
							<p className="middle">프로덕션</p>
							<p className="small">촬영·편집<br/>디자인·CG 제작</p>
							</div>
						</li>
						<li className="circle-line">
							<div>
							<p className="num">06</p>
							<p className="middle">포스트 프로덕션</p>
							<p className="small">DI(색보정)·종합 편집<br/>사운드 디자인<br/>믹싱·마스터링<br/>시사·검수</p>
							</div>
						</li>
						<li className="around blue">
							<div>
							<p className="num">07</p>
							<p className="small">납품</p>
							</div>
						</li>
						</ul>
					</div>
					<p className="sub-txt"><span className="m-block">프로젝트 규모와 일정에 따라 프로세스를 </span><span className="txt-bold">효율적으로 제시</span>해 드리고<br/><span className="txt-bold">최적의 콘텐츠를 제작</span> 가능합니다.</p>
					</div>
					</div>
					</div>

					</div>	


				  }
					


				</div>
				<div className="section sec3">
				
				{
					swiperOn ? 
						<div>
							<Swiper 
								modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel, EffectFade]}

							className="work-area product swiper-container" {...swiperSlideSet(2, 'fade', !wheelStateBelongFp)}>
								
								<div className="swiper-wrapper">

								<SwiperSlide className="swiper-slide">
									<div className="bg-black">
										<div className="bb-inner">
										<p className="title">Video Product</p>
										<p>기업, 브랜드들과 진행한 많은 영상 제작 경험으로<br/>트렌드 반영과 고객이 요구하는 최고의 결과물로 제작합니다.</p>
										</div>
									</div>
								</SwiperSlide>
								<SwiperSlide className="swiper-slide">
									<div className="bg-video">
										<video autoPlay={true} loop="" muted={true} playsInline="" id="work-bg" data-keepplaying>
										<source src={require('../data/videos/bg-product.mp4')}></source>
										</video>
									</div>
								</SwiperSlide>
							</div>

							</Swiper>
						</div> : 



						<div className="work-area product swiper-container">

							<div className="swiper-wrapper">
							<div className="swiper-slide">
								<div className="bg-black">
								<div className="bb-inner">
									<p className="title">Video Product</p>
									<p>기업, 브랜드들과 진행한 많은 영상 제작 경험으로<br/>트렌드 반영과 고객이 요구하는 최고의 결과물로 제작합니다.</p>
								</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="bg-video">
								<video autoPlay={true} loop="" muted={true} playsInline="" id="work-bg" data-keepplaying>
									<source src={require('../data/videos/bg-product.mp4')}></source>
								</video>
								</div>
							</div>
							</div>
						</div>

					}

				</div>
				<div className="section sec4">


				{
					swiperOn ? 
						<div>
							<Swiper 
								modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel, EffectFade]}

							className="work-area streaming swiper-container" {...swiperSlideSet(3, 'fade', !wheelStateBelongFp)}>
								<div className="swiper-wrapper">
									<SwiperSlide className="swiper-slide">
										<div className="bg-black">
										<div className="bb-inner">
											<p className="title">LIVE Streaming</p>
											<p>풍부한 경험을 기반으로 효율적인 시스템 구축과<br/> 안정적인 운영으로 비대면 행사를 송출합니다.</p>
										</div>
										</div>
									</SwiperSlide>
									<SwiperSlide className="swiper-slide">
										<div className="bg-video">
										<video autoPlay={true} loop="" muted={true} playsInline="" id="work-bg" data-keepplaying>
											<source src={require('../data/videos/bg-streaming.mp4')}></source>
										</video>
										</div>
									</SwiperSlide>
								</div>
								
							</Swiper>	
						</div>
						:

						<div className="work-area streaming swiper-container">
							<div className="swiper-wrapper">
							<div className="swiper-slide">
								<div className="bg-black">
								<div className="bb-inner">
									<p className="title">LIVE Streaming</p>
									<p>풍부한 경험을 기반으로 효율적인 시스템 구축과<br/> 안정적인 운영으로 비대면 행사를 송출합니다.</p>
								</div>
								</div>
							</div>
							<div className="swiper-slide">
								<div className="bg-video">
								<video autoPlay={true} loop="" muted={true} playsInline="" id="work-bg" data-keepplaying>
									<source src={require('../data/videos/bg-streaming.mp4')}></source>
								</video>
								</div>
							</div>
							</div>
						</div>
						}


				</div>
				<div className="section sec5">

				{
					swiperOn ? 
						<div>
							<Swiper 
								modules={[Navigation, Pagination, Scrollbar, A11y, Mousewheel, EffectFade]}

							className="work-area virtual swiper-container" {...swiperSlideSet(4, 'fade', !wheelStateBelongFp)}>
								<div className="swiper-wrapper">
									<SwiperSlide className="swiper-slide">
										<div className="bg-black">
											<div className="bb-inner">
											<p className="title">Virtual Contents</p>
											<p>크로마키 버츄얼 스튜디오에서 촬영과 3D 영상제작 기술을 결합하여<br/>인터렉티브하게 콘텐츠를 제작합니다.</p>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide className="swiper-slide">
										<div className="bg-video">
											<video autoPlay={true} loop="" muted={true} playsInline="" id="work-bg" data-keepplaying>
											<source src={require('../data/videos/bg-virtual.mp4')}></source>
											</video>
										</div>
									</SwiperSlide>
								</div>
								
							</Swiper>	
						</div>
						:
		
				  <div className="work-area virtual swiper-container">
					<div className="swiper-wrapper">
					  <div className="swiper-slide">
						<div className="bg-black">
						  <div className="bb-inner">
							<p className="title">Virtual Contents</p>
							<p>크로마키 버츄얼 스튜디오에서 촬영과 3D 영상제작 기술을 결합하여<br/>인터렉티브하게 콘텐츠를 제작합니다.</p>
						  </div>
						</div>
					  </div>
					  <div className="swiper-slide">
						<div className="bg-video">
						  <video autoPlay={true} loop="" muted={true} playsInline="" id="work-bg" data-keepplaying>
							<source src={require('../data/videos/bg-virtual.mp4')}></source>
						  </video>
						</div>
					  </div>
					</div>
				  </div>

					}

				</div>
				<div className="section sec6">
				  <div className="scroll">
					<div className="sec6-inner">
					  <p className="tit-sub">OUR WORKS</p>
					  <div className="works-tab">
						<ul>
						  <li><a href="#none" onClick={(e)=>{setBelong(0)}} className={"tab-btn" + (belong == 0 ? " on": "")}>전체</a></li>

							{
								belongList.map((v, i) => {

									return <li><a href="#none" onClick={(e)=>{setBelong(v.seed)}} className={"tab-btn" + (belong == v.seed ? " on": "")}>{v.name}</a></li>

								})
							}

						</ul>
					  </div>
					  <div className="works-con">
						<ul>

						{
								workList.map((work, index) => {
								
								let total = (work.thumbnail ? 1:0) + (work.thumbnail2 ? 1:0) + (work.thumbnail3 ? 1:0) + (work.thumbnail4 ? 1:0);
								

								return <>
										<li  key={index} className={"d"+(index%10) + (total == 0 ? " fadeInLeft" : "")}>
											<div className={"list-l"}><a href={work.dest_video} target="_blank"><div className="round-img">{work.thumbnail ? <img src={process.env.REACT_APP_BASE_URL + work.thumbnail} alt="" onLoad={(state)=>{addLoaded(index, total)}}/> : ""}</div></a></div>
											<div className="list-r">
											<div className="r-top">
												<p className="work-tit"><a href={work.dest_video} target="_blank">{work.summary}</a></p>
												<p className="work-txt">{work.title[0].toUpperCase() + work.title.substring(1)}</p>
											</div>
											<ul className="r-bottom">
												<li><div className="round-img">{work.thumbnail2 ? <img src={process.env.REACT_APP_BASE_URL + work.thumbnail2} alt="" onLoad={(state)=>{addLoaded(index, total)}}/> : ""}</div></li>
												<li><div className="round-img">{work.thumbnail3 ? <img src={process.env.REACT_APP_BASE_URL + work.thumbnail3} alt="" onLoad={(state)=>{addLoaded(index, total)}}/> : ""}</div></li>
												<li><div className="round-img">{work.thumbnail4 ? <img src={process.env.REACT_APP_BASE_URL + work.thumbnail4} alt="" onLoad={(state)=>{addLoaded(index, total)}}/> : ""}</div></li>
											</ul>
											</div>

											<div className="lds-ring"><div></div><div></div><div></div><div></div></div>


										</li>
									</>
								

							})
					}

						 

						</ul>
						<div className="btn-wrap">

						{workListPage != 0 && <a href="#" onClick={loadWorkPage} className="more-btn">{
								<>
									{workListIsLoading ? <LoadingSpinner/> : "view more"}
								</>

							}
							</a> }
						</div>
					  </div>
					</div>
					<div className="partner">
					  <p className="tit-sub">PARTNER</p>
					   <div className="partner-inner">
						<Slider ref={slider => setSlider(0, slider)} {...settings(0)} className="partner-list1">
							{getPageSlick(0)}
						</Slider>
						<Slider ref={slider => setSlider(0, slider)} {...settings(1)} className="partner-list2" dir="rtl">
							{getPageSlick(1)}
						</Slider>
						<Slider ref={slider => setSlider(0, slider)} {...settings(2)} className="partner-list3">
							{getPageSlick(2)}
						</Slider>
						<Slider ref={slider => setSlider(0, slider)} {...settings(3)} className="partner-list4" dir="rtl">
							{getPageSlick(3)}
						</Slider>
					  </div>
					</div>

					<div class="bottom-area">
						<div class="btn-inner">
							<DelayLink to="/Contact" class="work-link"
							>
							WORK WITH US
							</DelayLink>
						</div>
						<div class="bg-inner"></div>
					</div>

					
				  </div>
				</div>
				
				<Footer isSection={true}/>
				
			  </Fullpage>   
			</>
	  );
}

export default Main;
