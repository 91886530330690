// import {ReactComponent as Spinner}  from "../bower_components/SVG-Loaders/svg-loaders/spinning-circles.svg";
// import {ReactComponent as Spinner}  from "../bower_components/SVG-Loaders/svg-loaders/tail-spin.svg";
import React from "react";
import { Oval } from  'react-loader-spinner'

function Loading(props) {
  return (
	<div className={"loadingContainer" + (props.full ? ' full' : '')}>
        <div className="loadingRow">
            <div className="loadingCell">
                <Oval
                  height={16}
                  width={16}
                  color="#000"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel='oval-loading'
                  secondaryColor="#666"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

                />
            </div>
        </div>
	</div>
  );
}


Loading.defaultProps = {
    full:false
  }


export default Loading;
