import React, {useState, useRef, useEffect} from 'react'
import $ from "jquery";
// import axios from 'axios';
import axios from '../../components/Api';

//import {get, post} from '../components/Api';

import LoadingSpinner from "../../components/Loading";

import { alert } from 'react-custom-alert';

import CustomSelect from '../components/CustomSelect';

import "jquery-datetimepicker";

function Form() {

  const DEFAULT_PH = "파일첨부 (2개까지 업로드 가능, 최대 10MB)";

  const business = useRef(null);
  const name = useRef(null);
  const phone = useRef(null);
  const email = useRef(null);
  const privacy = useRef(null);
  const files_selected = useRef(null);
  const contents = useRef(null);
  
  const select1 = useRef(null);
  const select2 = useRef(null);
  const select3 = useRef(null);
  const select4 = useRef(null);

  
  const [type, setType] = useState(0);
  const [file, setFile] = useState([]);	//파일
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({});

  const [placeholder, setPlaceholder] = useState(DEFAULT_PH);
  
  const handleSubmit = (event) => {

        if(formIsSubmitting) return;

        var _nasa={};

        event.preventDefault();

        setFormIsSubmitting((state)=>{
          
          if(window.wcs) _nasa["cnv"] = window.wcs.cnv("4","0"); 

          const data = {
              type: type,

              select1: select1.current && select1.current.value,

              select2: select2.current && select2.current.value,

              select3: select3.current && select3.current.value,

              select4: select4.current && select4.current.value,

              business: business.current.value,
              name: name.current.value,
              phone: phone.current.value,
              email: email.current.value,
              contents: contents.current.value,
              // files_selected: business.current.value,
              privacy: privacy.current.value
          }
          
          if(data.business == '')
          {
            //alert("회사/기관명을 입력해주세요.");
            alert({ message: "회사/기관명을 입력해주세요.", type: 'info' });
            return false;
          }

          if(data.name == '')
          {
          //  alert("의뢰자명 입력해주세요.");
            alert({ message: "의뢰자명을 입력해주세요.", type: 'info' });
            return false;
          }

          if(data.phone == '')
          {
            //alert("휴대폰번호를 입력해주세요.");
            alert({ message: "휴대폰번호를 입력해주세요.", type: 'info' });
            return false;
          }

          if(data.email == '')
          {
            //alert("이메일을 입력해주세요.");
            alert({ message: "이메일을 입력해주세요.", type: 'info' });
            return false;
          }


          if(select1.current && select1.current.value == "")
          {
            console.log(select1.current)

            alert({ message: "제작 기간을 선택해주세요.", type: 'info' });
            return false;
          }


          if(select2.current && select2.current.value == "")
          {
            alert({ message: "예산 범위를 선택해주세요.", type: 'info' });
            return false;
          }


          if(select3.current && select3.current.value == "")
          {
            alert({ message: "이용 날짜 및 시간을 선택해주세요.", type: 'info' });
            return false;
          }


          if(select4.current && select4.current.value == "")
          {
            alert({ message: "유입 경로를 선택해주세요.", type: 'info' });
            console.log(select4.current.value)
            return false;
          }

          if(!privacy.current.checked)
          {
            //alert("개인정보처리방침에 동의해주세요.");
            alert({ message: "개인정보처리방침에 동의해주세요.", type: 'info' });
            return false;
          }

          //alert("tadaaa!: \n" + JSON.stringify(data) + "Your data 😎")

          let xs = "";

          const fd = new FormData();
          
          let fcheck = true;

          Object.values(file).forEach((file) =>{

            if(file.name.indexOf(".so") !== -1 || file.name.indexOf(".exe") !== -1 || !file.name.indexOf(".") === -1) {

                alert({ message: "유효한 파일 형식을 업로드해주세요.", type: 'info' });
                fcheck = false;
                return false;
                
            }

          })

          if(!fcheck)
          {
            return;
          }


          Object.values(file).forEach((file) => fd.append("file[]", file));


          Object.keys(data).forEach(function(key){

            fd.append(
              key,
              data[key]
            );

          });


          xs = phone.current.value + "528192" + email.current.value;

          fd.append(
            "xs",
            btoa(xs)
          );


          axios.post('/api/v2/contact/submit', fd, {
            headers: {
              "Content-Type": `multipart/form-data; `,
            }
          })
          .then((response) => {

             if(response.data){

                 //history.push("/test1");
                 if(response.data.res == 0)
                 {
                    if(window.wcs) _nasa["cnv"] = window.wcs.cnv("4","1"); 

                   //alert("소중한 문의 감사합니다. 빠른 시일 내에 답변드리겠습니다.");
                   alert({ message: "소중한 문의 감사합니다. 빠른 시일 내에 답변드리겠습니다.", type: 'success' });
                   clearForm();
                 }
                 else
                 {
                   //alert(response.data.msg);
                   alert({ message: response.data.msg, type: 'info' });
                 }

              }

              setFormIsSubmitting(false);

          })
          .catch((error) => {
            console.log(error);

            // 예외 처리
            //alert("제출에 실패했습니다. 잠시 후 다시 시도해주세요.");
            alert({ message: "제출에 실패했습니다. 잠시 후 다시 시도해주세요.", type: 'info' });

            if(window.wcs) _nasa["cnv"] = window.wcs.cnv("4","-1"); 

            setFormIsSubmitting(false);
          })

          return true;

        });

  }

  const clearForm = () => {
    business.current.value = null;
    name.current.value = null;
    phone.current.value = null;
    email.current.value = null;
    contents.current.value = null;
    // files_selected: business.current.value,
    privacy.current.value = null;

    //file.current.files = [];
    file.current.value = '';

    files_selected.current.value = '';

    setPlaceholder(DEFAULT_PH);
    setFile([]);

    privacy.current.checked = false;

  }

  const handleChangeFile = (event) => {

    let maxSize = 10485760;//10MB

    if(event.target.files.length == 0)
    {
      setPlaceholder(DEFAULT_PH);
    }
    else{

      let names = [];
      let size = 0;

      if(event.target.files.length > 2)
      {

        let dataTransfer = new DataTransfer();

        let farr = Array.from(event.target.files);

        farr = farr.slice(0, 2);

        farr.forEach(function(file, idx){
          //if(idx < 2)
          dataTransfer.items.add(file);
        });

        event.target.files = dataTransfer.files;

        alert({ message: "최대 2개까지 등록 가능합니다.", type: 'info' });


      }
      
      let fileArr = Array.from(event.target.files);

      fileArr.forEach(function(item){

        names.push(item.name);
        size += item.size;

      });
      
      if(maxSize < size)
      {
        alert({ message: "최대 10MB까지 등록 가능합니다.", type: 'info' });
        setFile([]);
        event.target.files = [];
        setPlaceholder(DEFAULT_PH);
      }
      else
      {
        setPlaceholder(names.join(", "));
      }
    }

    setFile(event.target.files);



  }

  const openFileSelector = (event) => {
    event.preventDefault();
    console.log(file.current);
    file.current.click();
  }

  useEffect(()=>{

    console.log("render reloaded");

  }, [formIsSubmitting]);


  useEffect(()=>{

    if(type == 2)
    {

		$.datetimepicker.setLocale('kr');
        var $startDate = $('.datepicker');
			$startDate.datetimepicker({
					timepicker: true,
					lang: 'ko',
					format: 'Y-m-d H:i',
					scrollMonth: false,
					scrollInput: false,
					showMonthAfterYear: true,
					buttonImage: "images/icon/ico-datepicker.png",
					buttonImageOnly: true,
					beforeShow: function (input, inst) { /*setDatepickerPos(input, inst)*/ },
		});

        placeholderTarget = $(".form-item input");
		var placeholderTarget = $(".form-item input");

		placeholderTarget.focus(function(){
				$(this).siblings("label").fadeOut('fast');
		});

		placeholderTarget.focusout(function(){
				if($(this).val() == ""){
						$(this).siblings("label").fadeIn('fast');
				}
		});
        
    }

  }, [type]);


  let getItemList = (no) => {

    if(no == 0)
    {
        return [
            "10일 이내",						    
            "15일 이내",						    
            "30일 이내",						    
            "1개월 이상",						    
            "6개월 이상"
        ]
    }
    else if(no == 1)
    {
        return [
            "500만 원 이하",						    
            "500만 원 ~ 3,000만원",						    
            "3,000만 원 ~ 5,000만원",						    
            "5,000만 원 ~ 1억 원",						    
            "1억 원 이상"
        ]
    }
    else if(no == 2)
    {
        return [
            "블로그",						    
            "검색",						    
            "인터넷 광고",						    
            "파트너사 추천",						    
            "기타"
        ]
    }


  }

   return(
     <>

       {formIsSubmitting ? <LoadingSpinner full={true}/> : ""}

       <form action="" data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <div className="form-con">
                <p className="form-num">01. 문의 유형을 선택해주세요.</p>
                <ul className="type-wrap">
                    
                    <li><a href="#" className={"con-type" + (type == 0 ? " on" : "")} onClick={(e) => {e.preventDefault(); setType(0);}}>영상제작</a></li>
                    <li><a href="#" className={"con-type" + (type == 1 ? " on" : "")} onClick={(e) => {e.preventDefault(); setType(1);}}>라이브 스트리밍</a></li>
                    <li><a href="#" className={"con-type" + (type == 2 ? " on" : "")} onClick={(e) => {e.preventDefault(); setType(2);}}>스튜디오 대여</a></li>

                </ul>


                <p className="form-num">02. 귀하의 정보를 작성해주세요.</p>
                <div className="form-line first">
                    <div className="form-item"><input type="text" id="company" ref={business} placeholder=" " autoComplete="off"/><label for="company">회사/기관명<span>*</span></label></div>
                    <div className="form-item"><input type="text" id="name" ref={name} placeholder=" " autoComplete="off"/><label for="name">의뢰자명<span>*</span></label></div>
                </div>	
                <div className="form-line">
                    <div className="form-item"><input type="number" ref={phone} placeholder=" " pattern="\d*" id="phone" autoComplete="off"/><label for="phone">휴대폰번호<span>*</span></label></div>
                    <div className="form-item"><input type="email" ref={email} placeholder=" " id="mail" autoComplete="off"/><label for="mail">이메일<span>*</span></label></div>
                </div>
                <p className="form-num">03. 문의 내용을 상세하게 기재해주세요.</p>
                <div className="sel-wrap">

                    {
                        type == 0 ?

                        <>
                            

                            <div className="sel-inner">
                                <CustomSelect ref={select1} key="c1" default="제작 기한" name="f1" itemList={getItemList(0)}/>
                            </div>

                            <div className="sel-inner">
                                <CustomSelect ref={select2} key="c2" default="예산 범위" name="f2" itemList={getItemList(1)}/>	
                            </div>
                        </>

                        : (
                            type == 1 ?

                            <>
                                <div className="sel-inner">
                                    <CustomSelect ref={select2} key="c2" default="예산 범위" name="f2" itemList={getItemList(1)}/>		
								</div>
                            </>

                            : 
                            <>
                              <div className="sel-inner date">
									<input type="text" ref={select3} id="datepicker" className="datepicker" placeholder="이용 날짜 및 시간" readOnly/>
								</div>
								
                            </>

                        )

                            

                    }


                    <div className="sel-inner">
                        <CustomSelect ref={select4} key="c3" default="유입 경로" name="f3" itemList={getItemList(2)}/>				
                    </div>
                    




                </div>


                <div className="form-line tmg">
                    <textarea name="contents" ref={contents} id="" rows="10" placeholder="제작 목적, 참고할(레퍼런스) 영상 링크, 추가적인 설명 또는 기타 요청 사항을 알려주세요."></textarea>
                </div>



                <div className="form-line tmg iptBtn">
                    <div className="form-item border"><input type="text"  ref={files_selected} placeholder={placeholder} disabled="disabled"/></div>
                    <div className="form-item">
                        <a href="#" className="line-btn" onClick={openFileSelector}><i className="ico-down"></i></a>
                        <input type="file" ref={file} onChange={handleChangeFile} multiple="multiple" style={{display:"none"}}/>
                    </div>
                </div>


                <div className="form-line tmg item">
                    <label><span className="checkbox"><input type="checkbox" ref={privacy} value="1" required/><i></i></span>개인정보처리방침에 동의합니다.</label>
                    <a href="https://avivland.com/Policy" target="_blank" className="txt-link">내용보기</a>
                </div>
                <div className="form-line tmg2">
                    <button type="button" onClick={(e)=>{handleSubmit(e)}}>SUBMIT</button>
                </div>
            </div>
        </form>

       </>
  )
}

export default Form
