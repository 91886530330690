// /src/api/index.js
import axios from 'axios';

axios.defaults.baseURL  = process.env.REACT_APP_BASE_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const apiClient = axios.create({
//       baseUrl: process.env.REACT_APP_BASE_URL   // 환경변수로 지정한 BASE_URL을 사용
// });


// console.log("BASE:" + process.env.REACT_APP_BASE_URL);

// interceptor처리도 작성한다.

export default axios;
