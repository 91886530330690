import React, {useState, useEffect} from 'react';


//fullpage V3
// import ReactDOM from 'react-dom';
// import ReactFullpage from '@fullpage/react-fullpage';
/*
const Fullpage = (props) => {

	let state = { 
		scrollOverflow: true,	      
		scrollingSpeed: 500,
		bigSectionsDestination: 'top',
		responsiveWidth: 1024,
		// normalScrollElements: '.sec4',
		// scrollBar: true,
		
		onLeave: props.onLeave,
		afterRender: props.afterRender,
		afterLoad: props.afterLoad
	}



	return <ReactFullpage


	  //fullpage options
	  {...state}


	  render={({ state, fullpageApi }) => {
		return (
		  <ReactFullpage.Wrapper>
			{props.children}
		  </ReactFullpage.Wrapper>
		);
	  }}
	/>

}*/

// import $ from "jquery";
import $ from "jquery";
import '../../js/iscroll.js'
import 'fullpage.js/vendors/scrolloverflow'
import 'fullpage.js';
import 'fullpage.js/dist/jquery.fullpage.min.css';

const Fullpage = (props) => {

	const MODE_NOT_INIT = -1, MODE_DESKTOP = 0, MODE_MOBILE = 1

	let mode = MODE_NOT_INIT
	let fullpageInit = false;

    let state = { 
		scrollOverflow: true,	      
		scrollingSpeed: 500,
		bigSectionsDestination: 'top',
		responsiveWidth: 1024,
		// normalScrollElements: '.sec4',
		// scrollBar: true,
		
		onLeave: props.onLeave,
		afterRender: props.afterRender,
		afterLoad: props.afterLoad
	}

	let onResize = () => {
		
		if(window.innerWidth > 1024)
		{
			if(mode != MODE_DESKTOP)
			{
				mode = MODE_DESKTOP


				if($.fn.fullpage && $.fn.fullpage.destroy)
				{
					$.fn.fullpage.destroy('all');
					fullpageInit = false;
				}

				setTimeout(()=>{

					if(!fullpageInit) {
						fullpageInit = true;
						console.log("reset fullpage")
						$('#fullpage').fullpage(state)
						$.fn.fullpage.setAllowScrolling(false);
					}
 
				}, 200)
				
			}
		}
		else
		{
			if(mode != MODE_MOBILE)
			{
				mode = MODE_MOBILE

				if($.fn.fullpage && $.fn.fullpage.destroy)
				{
					$.fn.fullpage.destroy('all');
					fullpageInit = false;
				}

				$("#fullpage").addClass("fullpage-wrapper")

				// setTimeout(()=>{
				// 	$('#fullpage').fullpage(state)
				// }, 200)
			}
		}

	}

	window.addEventListener("resize", onResize);

    useEffect(()=>{

		window.removeEventListener("resize", onResize);


        window.fullpage = $.fn.fullpage
		window.pageObj = $('#fullpage')
		// window.pageObj.fullpage(state);

		onResize()

        return () => {
			if($.fn.fullpage && $.fn.fullpage.destroy)
			{
            	$.fn.fullpage.destroy('all');
			}
        }
    }, []) 
    
    return (
        <>
            <div id="fullpage">
                {props.children}
            </div>
        </>
            
        )

}



export default Fullpage;