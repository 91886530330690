import { Routes, Route, useLocation } from 'react-router-dom';
import Main from './pages/Main';
import About from './pages/About';
import Contact from './pages/Contact';
import Work from '../pages/Work';
import WorksList from './pages/WorkList';
import Studio from './pages/Studio';

import React, {useState, useEffect} from 'react';

function App(props) {

  let [workListPage, setWorkListPage] = useState(1);
	let [workList, setWorkList] = useState([]);

  useEffect(

    () => {
      props.onRoute()
    }

  , [useLocation()])

  return (
   <Routes>
		<Route path="/" element={<Main onBind={props.onBind} workList={workList} setWorkList={setWorkList} workListPage={workListPage} setWorkListPage={setWorkListPage}/>} />
		<Route path="/Contact" element={<Contact onBind={props.onBind}/>} />
		<Route path="/Works" element={<WorksList onBind={props.onBind}/>} />
		<Route path="/Works/:id" element={<Work onBind={props.onBind}/>} />
    <Route path="/About" element={<About onBind={props.onBind}/>} />
    <Route path="/Studio" element={<Studio onBind={props.onBind}/>} />

   </Routes>
   
  );
}

export default App;
