import PropTypes from 'prop-types';
import React from 'react';
import $ from "jquery";
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils';

import LoadingSpinner from "./Loading";

// import classes from 'styles/YouTubeVideo.module.css';

class YouTubeVideo extends React.PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
  };


  componentDidUpdate = () => {
    this.loaded = false;

    const { url, no } = this.props;
    this.loadVideo(true);


  }

  componentDidMount = () => {

    this.loaded = false;

    // On mount, check to see if the API script is already loaded

    if (!window.YT_IMPORTED) { // If not, load the script asynchronously

      window.YT_IMPORTED = true;

      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


    } else { // If script is already there, load the video directly
      this.loadVideo();
    }
  };

  loadVideo = (reload) => {
    const t = this;
    const { url, no, on } = this.props;

    const id = this.youtube_parser(url);

    console.log(no + " ID: " + id);

    t.loaded = true;

    if(reload)
    {
        //var control = $("#videoCon"+no).prev();
        var parent = $("#videoCon"+no).parent();
        var root = parent.closest("list-videoCon-item");
        var empty = $('<div id='+("videoCon" + no)+'></div>');

        $("#videoCon"+no).remove();
        parent.append(empty);
 
        if(no == '0')
        {
          root.addClass("on");
          root.find(".videoCon-item, .videoCon-name, .videoCon").addClass("on");
        }
        else
        {
          root.removeClass("on");
          root.find(".videoCon-item, .videoCon-name, .videoCon").removeClass("on");
        }
    }

    if(window.YT && window.YT.Player)
    {

        // the Player object is created uniquely based on the id in props
        this.player = new window.YT.Player(`videoCon${no}`, {
            videoId: id,
            height: '',
            width:'',
            playerVars: { 'loop': 1, 'color' : 'blue', 'rel' : 0 },
            events: {
                onReady: this.onPlayerReady,
            },
        });

        if(!window.YPLAYER)
        {
            window.YPLAYER = {};
        }

        window.YPLAYER[no] = this.player;

        console.log("load video" + `videoCon-${no}`);

    } else {

        setTimeout(function(){
            t.loadVideo();
        }, 100)

    }


  };


   youtube_parser(url) {
     if(url.indexOf("https://youtu.be/") == 0)
     {
        let arr = url.split("/");
        return arr[arr.length-1];
     }
     else
     {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match&&match[7].length==11)? match[7] : false;
     }
  }


  onPlayerReady = event => {
    const { no, on } = this.props;
    const t = this;

    if(on && t.player && typeof t.player.playVideo == 'function')
    {
        $("#videoCon" + no).addClass("on");
        t.player.playVideo();
    }

    if( $("#videoCon" + no).hasClass("on") && t.player && typeof t.player.playVideo == 'function')
    {
        t.player.playVideo();
    }
    else
    {

    }



    //event.target.playVideo();
  };

  render = () => {
    const { url, on, no, title } = this.props;
    const t = this;

    return (
      <>
        <div className={"videoCon-item" + (no == 0 ? " on" : "")}>
            <div className={"videoCon-name" + (no == 0 ? " on" : "")} data-video-label={no}
                onClick={(e)=>{

                    $(e.target).toggleClass("on");

                    if( $(e.target).hasClass("on"))
                    {
                        if(typeof t.player.playVideo == 'function')
                        {
                            t.player.playVideo();
                        }

                        window.YPLAYER && Object.keys(window.YPLAYER).forEach(function(key){

                            if(key != no && window.YPLAYER[key])
                            {
                                if(typeof window.YPLAYER[key].playVideo == 'function')
                                {
                                    window.YPLAYER[key].stopVideo();
                                    console.log("stop video");
                                }
                            }
                        });
                    }
                    else
                    {
                        console.log("stop video");
                        if(typeof t.player.playVideo == 'function')
                        {
                             t.player.stopVideo();
                        }
                    }



                    $(".videoCon-name").not($(e.target)).removeClass("on");
                    var videoNum = $(e.target).attr("data-video-label");
                    $(".videoCon[data-video-item="+videoNum+"]").toggleClass("on");
                    $(".videoCon:not([data-video-item="+videoNum+"])").removeClass("on");
                }}

            >{title + ""}</div>
        </div>

        {this.loaded ? <div className={"videoCon" + (on ? " on" : "")} data-video-item={no}>
            <div id={"videoCon" + no}>
            </div>
        </div> : <div className={"videoCon" + (on ? " on" : "")} data-video-item={no}>
          <div id={"videoCon" + no}>
          </div>

        </div>}
      </>
    );
  };
}



export default YouTubeVideo;
