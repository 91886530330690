import { ReactComponent as Loading } from "../../images/loading.svg";
import React, {useState} from 'react';
import $ from "jquery";
import axios from '../../components/Api';

import DelayLink from "../../components/DelayLink";

import AOS from "aos";
import "aos/dist/aos.css";

class WorkList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            funcSizechk:'n',
            portfolioList:[]
        };


        //let [portfolioList, setPortfolioList] = useState([]);


    }

    loadPortfolio() {
        let t = this;

        if(this.state.portfolioList.length > 0)
        {
            console.log("load portfolio");
            return;
        }

        var apiParams = new URLSearchParams();
        apiParams.append('order', 'asc');

		axios.post("/api/get/portfolioGroup", apiParams)
		.then((res)=>{

			res = res.data;
            //console.log(res);


			if(res.resCode == '0')
			{
				let resList = res.result;

				//t.state.portfolioList = resList;
                //setPortfolioList(resList);

                t.setState({portfolioList:resList});

                //workList();

                if(typeof this.props.onBind == 'function')
              	{
              		this.props.onBind();
              	}

                console.log(resList)


			}
			else
			{
				console.log("failed");
				console.log(res);
			}

		})
		.catch(()=>{
			console.log("catch exception")

		})

	}

    componentWillUnmount() {
        let t = this;
        $(window).off('scroll', t.workListScroll);
        $('body').off('mousemove', t.workListMove);
        $(".list-work").find("a").off("mouseenter", t.workListItemEnter);

         window.removeEventListener("resize", t.workSizeFunc);

    }

    

    componentDidMount() {

        let t = this;



        if(typeof t.props.onBind == 'function')
        {
            t.props.onBind();
        }


        AOS.init();
        

        t.loadPortfolio();


    }

    componentDidUpdate() {

    }

    sectionType1(list){

        return (
            <>
                <div class="section-left">
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[0].seed}>
                            <div class="infoTxt">
                                <p>{list[0].name}</p>
                                <strong>{list[0].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[0].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                </div>
    
                <div class="section-right">
                    
                </div>
            </>
        )
    }

    sectionType2(list){

        return (
            <>
                <div class="section-left">
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[0].seed}>
                            <div class="infoTxt">
                                <p>{list[0].name}</p>
                                <strong>{list[0].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+ process.env.REACT_APP_BASE_URL+list[0].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                </div>
    
                <div class="section-right">
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[1].seed}>
                            <div class="infoTxt">
                                <p>{list[1].name}</p>
                                <strong>{list[1].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[1].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                </div>
            </>
        )


    }

    sectionType3(list){

        return (
            <>
                <div class="section-left">
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[0].seed}>
                            <div class="infoTxt">
                                <p>{list[0].name}</p>
                                <strong>{list[0].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[0].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                </div>
    
                <div class="section-right">
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[1].seed}>
                            <div class="infoTxt">
                                <p>{list[1].name}</p>
                                <strong>{list[1].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[1].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[2].seed}>
                            <div class="infoTxt">
                                <p>{list[2].name}</p>
                                <strong>{list[2].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[2].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>

                </div>
            </>
        )

    }

    sectionType4(list){

        return (
            <>
                <div class="section-left">
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[0].seed}>
                            <div class="infoTxt">
                                <p>{list[0].name}</p>
                                <strong>{list[0].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[0].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                </div>
    
                <div class="section-right">
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[1].seed}>
                            <div class="infoTxt">
                                <p>{list[1].name}</p>
                                <strong>{list[1].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[1].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[2].seed}>
                            <div class="infoTxt">
                                <p>{list[2].name}</p>
                                <strong>{list[2].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[2].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>
                    <div class="section-inner">
                        <DelayLink to={"/Works/" + list[3].seed}>
                            <div class="infoTxt">
                                <p>{list[3].name}</p>
                                <strong>{list[3].client}</strong>
                            </div>
                            <div class="worksBg" style={{backgroundImage:'url("'+process.env.REACT_APP_BASE_URL+list[3].thumbnail+'")'}}></div>
                            
                        </DelayLink>
                    </div>

                </div>
            </>
        )

    }

    sectionType(list){

        switch(list.length)
        {
            case 1: 
                return this.sectionType1(list);
            case 2: 
                return this.sectionType2(list);
            case 3: 
                return this.sectionType3(list);
            case 4: 
                return this.sectionType4(list);
        }

        return null;
    }

    render() {
        return (

            <>
                <div id="sub-container">
                    {
                        this.state.portfolioList.map((portfolio, index) => (
                            
                            
                            <div className={"work-section type"+portfolio.portfolios.length}>
                                
                                {
                                    this.sectionType(portfolio.portfolios)
                                }
                            </div>


                        ))
                    }
 
                </div>
                
        </>


        );
    }



}

export default WorkList;
