import { Link, useLocation } from 'react-router-dom';
import React, {useEffect} from 'react';
import $ from "jquery";

import DelayLink from "../../components/DelayLink";

function FloatingContact() {

	const location = useLocation(); // location.pathname 이런식으로 주소접근

	// useEffect(()=>{
	// 	console.log(location.pathname);
	// }, [location]);


	if(location.pathname.toLowerCase() != '/contact' && !location.pathname.toLowerCase().match(/works\/[0-9]+/))
	{
        return (
            <DelayLink to="/contact" className="contact-btn"
			
							
			delay={500}

			whenDelayStart={ ()=> {
				
				$('.contact-btn').addClass("active");

			} }
			whenDelayEnd={ ()=> {
				
				
				$('.contact-btn').removeClass("active");

			} }
			


			>
                contact
            </DelayLink>
        );
    }
	else{

		$(".pointer").removeClass("hover");

		return null;
	}

}

  export default FloatingContact;
