import React, {useEffect} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import $ from "jquery";

/**
 * Wraps the React Router Link component and creates a delay after the link is clicked.
 */
function DelayLink (props) {

  let contextTypes = Link.contextTypes;
  let timeout = null;
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {




    return {
        if(timeout) {
            clearTimeout(timeout);
        }
    };

  });

  /**
   * Called when the link is clicked
   *
   * @param {Event} e
   */
  let handleClick = (e) => {
    const { replace, to, delay, whenDelayStart=()=>{}, whenDelayEnd=()=>{} } = props;
    //const navigate = useNavigate();

    const t = this;

    if(location.pathname == to)
    {
        return;
    }


    if(typeof whenDelayStart == 'function')
    {
        whenDelayStart(e, to);
    }


    // if (e.defaultPrevented) {
    //   return;
    // }
    e.preventDefault();

    timeout = setTimeout(() => {


      navigate(to, {replace:replace});

      if(typeof whenDelayEnd == 'function')
      {
        whenDelayEnd(e, to);
      }

    }, delay);
  };


  return (
    <><Link {...props} onClick={handleClick} /></>
  );


}

DelayLink.defaultProps = {
    replace:false,
    delay: 300,
    // whenDelayStart: ()=>{

    //     // $(".effecter").css({
    //     //     top:$(".pointer").css("top"),
    //     //     left:$(".pointer").css("left")}
    //     // );

    //     // $('body').addClass("blur");
    //     //$("body").stop().fadeOut();
    // },
    // whenDelayEnd: ()=>{

    //     // $(".effecter").css({
    //     //     top:$(".pointer").css("top"),
    //     //     left:$(".pointer").css("left")}
    //     // );


    //     // $('body').removeClass("blur");
    //     //$("body").stop().fadeIn();
    //   }
}

export default DelayLink;
