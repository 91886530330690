import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Layout from './v2/Layout';
// import Pointer from './components/Pointer';
import reportWebVitals from './reportWebVitals';

import {$, jQuery} from 'jquery';

ReactDOM.render(
  // <React.StrictMode>
  <><Layout/>
  {/* <Pointer/> */}
  </>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
